import React from "react";

function UnderConstruction() {
    return (
        <section className="bg-slate-50">
            <div class="w-full h-screen flex justify-center items-center p-2">
                <div class="w-1/2">
                    <h1 class="mb-5 text-[40px] font-bold text-yellow-500 capitalize">
                        🚧 Website under construction 🚧
                    </h1>
                    <p class="text-black text-base font-semibold">
                        We are working to improve our site, come back soon!
                    </p>
                </div>
                <div>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/recursos-31efd.appspot.com/o/BG%2Funderconstruction.png?alt=media&token=1e64685f-d90c-4ece-af4c-11972df11327"
                        alt="Under Construction"
                        class="w-[550px] h-auto"
                    />
                </div>
            </div>
        </section>
    );
}

export default UnderConstruction;